body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border: none;
  height: 1px;
  color: #ddd;
  background-color: #ddd;
}